const MainLogoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="226"
    height="40"
    viewBox="0 0 226 40"
    fill="none"
  >
    <path
      d="M63.4265 19.0756H73.1139V21.2681C73.1139 24.1572 72.5743 26.6047 71.5884 28.0615C70.0336 30.4453 67.017 31.7157 62.9508 31.7157C58.8845 31.7157 56.28 30.666 54.1856 28.4441C52.2187 26.3153 51.2622 23.647 51.2622 20.189C51.2622 12.8512 55.7699 8.27979 63.0096 8.27979C66.4088 8.27979 69.1997 9.10382 71.5198 10.85L69.9943 13.6115C68.3119 12.3117 65.8643 11.5465 63.3873 11.5465C58.5902 11.5465 55.5442 14.8475 55.5442 20.0566C55.5442 22.3423 56.0838 24.2798 57.1972 25.8052C58.497 27.6152 60.2776 28.4392 62.9753 28.4392C66.9778 28.4392 69.0084 26.6293 69.0084 23.1026V22.0529H63.4216V19.0658L63.4265 19.0756Z"
      fill="#028A19"
    />
    <path
      d="M102.612 19.9977C102.612 26.9824 97.9132 31.7157 90.9236 31.7157C83.934 31.7157 79.1418 27.0462 79.1418 19.8408C79.1418 16.7605 80.1915 13.8714 82.0652 11.8702C84.2577 9.48641 87.2105 8.27979 90.8942 8.27979C97.9132 8.27979 102.612 12.9787 102.612 19.9977ZM83.4288 19.8996C83.4288 25.1725 86.2541 28.4441 90.8893 28.4441C95.5245 28.4441 98.3203 25.1431 98.3203 19.9977C98.3203 14.8524 95.4313 11.5514 90.8893 11.5514C86.3473 11.5514 83.4288 14.823 83.4288 19.9046V19.8996Z"
      fill="#028A19"
    />
    <path
      d="M109.818 8.65747H113.948V28.0615H122.492V31.3331H109.823V8.65747H109.818Z"
      fill="#028A19"
    />
    <path
      d="M128.329 8.65747H140.841V11.9291H132.459V18.1829H140.145V21.4545H132.459V31.3282H128.329V8.65747Z"
      fill="#028A19"
    />
    <path
      d="M157.793 8.65747H161.923V28.0615H170.467V31.3331H157.798V8.65747H157.793Z"
      fill="#028A19"
    />
    <path d="M176.31 8.65747H180.44V31.3331H176.31V8.65747Z" fill="#028A19" />
    <path
      d="M186.467 8.65747H190.847L196.341 26.026L201.835 8.65747H206.215L198.151 31.4607H194.561L186.462 8.65747H186.467Z"
      fill="#028A19"
    />
    <path
      d="M212.282 8.65747H225.364V11.9291H216.314V17.9622H224.349V21.2338H216.314V28.0615H225.776V31.3331H212.277V8.65747H212.282Z"
      fill="#028A19"
    />
    <path
      d="M19.9976 0C8.95158 0 0 8.95156 0 19.9975C0 28.1545 4.88536 35.1686 11.8848 38.2784V10.4721C11.8848 10.3691 11.8946 10.271 11.9093 10.1729C12.0564 7.83323 13.8124 6.92091 15.9559 8.15205L32.623 17.7118C34.8646 18.9969 34.8646 21.0815 32.623 22.3715L15.9559 31.9313C15.8823 31.9755 15.8087 32.0147 15.7352 32.049V39.5438C17.1086 39.843 18.531 40 19.9927 40C31.0387 40 39.9903 31.0484 39.9903 20.0025C39.9903 8.95647 31.0387 0 19.9976 0Z"
      fill="#028A19"
    />
  </svg>
);

const WrongPaymentSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="164"
    height="119"
    viewBox="0 0 164 119"
    fill="none"
  >
    <path
      d="M66.8137 24.9453L67.2246 24.2357L72.4617 27.2679L72.0509 27.9775L66.8137 24.9453Z"
      fill="#161616"
    />
    <path
      d="M78.8881 15.0859L79.6993 14.9667L80.8435 22.7524L80.0322 22.8716L78.8881 15.0859Z"
      fill="#161616"
    />
    <path
      d="M90.8209 25.7617L93.8038 21.569L94.472 22.0444L91.4891 26.2371L90.8209 25.7617Z"
      fill="#161616"
    />
    <path
      d="M71.9681 35.5391L27.5514 40.5752C27.0107 40.6335 26.4869 40.7982 26.0102 41.06C25.5335 41.3218 25.1134 41.6754 24.7742 42.1005C24.4349 42.5255 24.1832 43.0136 24.0335 43.5364C23.8839 44.0593 23.8394 44.6066 23.9024 45.1467L30.1166 98.4836C30.1797 99.0246 30.3494 99.5477 30.616 100.023C30.8826 100.498 31.2407 100.915 31.6697 101.251C32.0987 101.586 32.59 101.833 33.1152 101.978C33.6403 102.122 34.1889 102.161 34.7291 102.092L81.1698 96.8122L76.4712 80.24L78.5595 66.6676L71.5116 51.6562L75.6881 45.5267L71.9681 35.5391Z"
      fill="#161616"
    />
    <path
      d="M70.1409 36.1914L25.7243 40.9679C25.1836 41.026 24.6597 41.1906 24.183 41.4522C23.7063 41.7139 23.2861 42.0673 22.9467 42.4922C22.6073 42.9171 22.3554 43.4051 22.2056 43.9278C22.0558 44.4506 22.0111 44.9979 22.0739 45.538L28.2895 98.8749C28.3527 99.4158 28.5226 99.9389 28.7892 100.414C29.0558 100.889 29.414 101.306 29.8429 101.641C30.2719 101.977 30.7631 102.224 31.2882 102.368C31.8133 102.513 32.3618 102.552 32.902 102.483L79.3427 96.5516L74.6441 80.6286L76.7324 67.0562L69.6845 52.0488L73.861 45.9139L70.1409 36.1914Z"
      fill="#CCCCCC"
    />
    <path
      d="M32.369 102.927C31.2602 102.925 30.1906 102.516 29.3631 101.778C28.5356 101.04 28.0077 100.024 27.8795 98.9228L21.6666 45.5859C21.5973 44.992 21.6464 44.3903 21.8109 43.8155C21.9755 43.2406 22.2523 42.7041 22.6254 42.2369C22.9984 41.7696 23.4604 41.3809 23.9846 41.0932C24.5087 40.8055 25.0847 40.6245 25.6792 40.5607L70.4115 35.75L74.3202 45.9686L70.1546 52.0871L77.156 66.9961L77.1369 67.1205L75.0637 80.6026L79.8716 96.8974L32.9526 102.89C32.759 102.915 32.5641 102.927 32.369 102.927ZM69.8717 36.6329L25.768 41.3752C25.2811 41.4277 24.8094 41.5761 24.3801 41.8118C23.9509 42.0475 23.5725 42.3659 23.2669 42.7486C22.9614 43.1313 22.7346 43.5707 22.5997 44.0415C22.4649 44.5123 22.4246 45.0051 22.4812 45.4916L28.6995 98.8271C28.7568 99.314 28.91 99.7847 29.1502 100.212C29.3904 100.639 29.7129 101.015 30.0991 101.317C30.4852 101.619 30.9274 101.842 31.4 101.972C31.8727 102.102 32.3664 102.137 32.8528 102.076L78.8165 96.199L74.2245 80.6573L74.2382 80.5671L76.3073 67.1205L69.2143 52.0133L73.4018 45.8633L69.8717 36.6329Z"
      fill="#161616"
    />
    <path
      d="M48.2384 53.1048L33.2574 54.7296C32.3382 54.8293 31.6738 55.6553 31.7735 56.5746L32.9726 67.6303C33.0723 68.5495 33.8983 69.2139 34.8175 69.1142L49.7986 67.4894C50.7178 67.3897 51.3821 66.5637 51.2824 65.6444L50.0834 54.5887C49.9837 53.6695 49.1577 53.0051 48.2384 53.1048Z"
      fill="#CCCCCC"
    />
    <path
      d="M34.6348 69.5334C34.122 69.532 33.6278 69.3417 33.2465 68.9988C32.8653 68.6559 32.6239 68.1845 32.5684 67.6747L31.3685 56.617C31.3094 56.0687 31.4699 55.5192 31.8149 55.089C32.1599 54.6587 32.6613 54.3825 33.2094 54.321L48.1921 52.6961C48.4649 52.6661 48.7409 52.6903 49.0044 52.7672C49.2678 52.8441 49.5134 52.9722 49.7272 53.1443C49.941 53.3163 50.1188 53.5288 50.2503 53.7697C50.3818 54.0106 50.4645 54.275 50.4936 54.5479L51.6935 65.6042C51.7529 66.1537 51.5916 66.7043 51.2451 67.1348C50.8986 67.5654 50.3953 67.8407 49.8458 67.9002L34.8644 69.5266C34.788 69.5329 34.7114 69.5352 34.6348 69.5334ZM33.3037 55.1369C32.9705 55.1734 32.6655 55.3405 32.4554 55.6015C32.2453 55.8626 32.1474 56.1964 32.183 56.5296L33.3829 67.5859C33.4008 67.7509 33.451 67.9107 33.5307 68.0562C33.6104 68.2018 33.7179 68.3302 33.8473 68.4342C33.9766 68.5381 34.1252 68.6155 34.2844 68.6621C34.4437 68.7086 34.6106 68.7232 34.7756 68.7052L49.7583 67.0802C49.9233 67.0624 50.0831 67.0121 50.2287 66.9325C50.3742 66.8528 50.5026 66.7452 50.6066 66.6159C50.7105 66.4865 50.788 66.338 50.8345 66.1787C50.881 66.0194 50.8957 65.8525 50.8776 65.6876L49.6777 54.6313C49.6416 54.298 49.4746 53.9927 49.2134 53.7826C48.9523 53.5724 48.6183 53.4746 48.2851 53.5106L33.3037 55.1369Z"
      fill="#161616"
    />
    <path
      d="M50.3082 72.9845L34.7454 74.7329C34.3156 74.7812 34.0064 75.1687 34.0547 75.5985L34.4335 78.9707C34.4818 79.4005 34.8693 79.7098 35.2991 79.6615L50.8619 77.9131C51.2917 77.8648 51.6009 77.4773 51.5526 77.0475L51.1738 73.6753C51.1255 73.2455 50.7379 72.9362 50.3082 72.9845Z"
      fill="white"
    />
    <path
      d="M35.2061 80.077C34.9135 80.075 34.6318 79.9654 34.4148 79.769C34.1978 79.5727 34.0606 79.3034 34.0294 79.0124L33.6509 75.6394C33.6159 75.3251 33.7071 75.0097 33.9044 74.7624C34.1016 74.5152 34.3889 74.3563 34.7032 74.3206L50.2654 72.574C50.4212 72.5563 50.5788 72.5695 50.7295 72.6129C50.8801 72.6562 51.0206 72.7289 51.1431 72.8266C51.2656 72.9244 51.3677 73.0453 51.4434 73.1825C51.5191 73.3198 51.5669 73.4706 51.5843 73.6263L51.9628 76.9979C51.9982 77.3123 51.9071 77.6279 51.7098 77.8753C51.5124 78.1226 51.2249 78.2814 50.9105 78.3167L35.3414 80.0702C35.2963 80.0743 35.2512 80.077 35.2061 80.077ZM34.7879 75.142C34.69 75.153 34.6004 75.2023 34.5387 75.2791C34.477 75.3559 34.4482 75.454 34.4586 75.552L34.8371 78.9249C34.8478 79.0231 34.897 79.113 34.9738 79.175C35.0119 79.2055 35.0557 79.2281 35.1026 79.2414C35.1496 79.2548 35.1987 79.2587 35.2471 79.2529L50.8121 77.509C50.91 77.498 50.9996 77.4488 51.0613 77.3719C51.123 77.2951 51.1518 77.197 51.1415 77.099L50.7629 73.7275C50.7519 73.6296 50.7026 73.54 50.6258 73.4783C50.549 73.4166 50.4509 73.3877 50.3529 73.3981L34.7879 75.142Z"
      fill="#161616"
    />
    <path
      d="M71.6941 70.4961L56.1347 72.2744C55.705 72.3235 55.3965 72.7116 55.4456 73.1413L55.8309 76.5128C55.88 76.9425 56.2682 77.251 56.6979 77.2019L72.2572 75.4237C72.6869 75.3745 72.9955 74.9864 72.9463 74.5567L72.561 71.1852C72.5119 70.7555 72.1238 70.447 71.6941 70.4961Z"
      fill="white"
    />
    <path
      d="M56.6139 77.6106C56.3208 77.6103 56.0382 77.5019 55.8201 77.3062C55.602 77.1106 55.4637 76.8413 55.4317 76.55L55.0463 73.1798C55.0284 73.0241 55.0414 72.8664 55.0845 72.7157C55.1275 72.565 55.1999 72.4243 55.2973 72.3016C55.3948 72.1789 55.5155 72.0765 55.6525 72.0005C55.7895 71.9244 55.9402 71.8761 56.0959 71.8582L71.6554 70.0816C71.9695 70.0466 72.2847 70.1373 72.5321 70.334C72.7794 70.5307 72.9389 70.8173 72.9756 71.1312L73.361 74.5C73.3968 74.8142 73.3063 75.1298 73.1095 75.3774C72.9127 75.6249 72.6256 75.7842 72.3114 75.8202L56.7519 77.5969C56.7054 77.6078 56.6603 77.6106 56.6139 77.6106ZM56.1888 72.6755C56.0912 72.6869 56.0019 72.7363 55.9405 72.8131C55.8791 72.8898 55.8505 72.9877 55.8608 73.0855L56.2462 76.4557C56.2515 76.5042 56.2663 76.5512 56.2898 76.594C56.3133 76.6367 56.345 76.6744 56.3831 76.7049C56.4212 76.7354 56.4649 76.7581 56.5118 76.7716C56.5587 76.7851 56.6078 76.7892 56.6562 76.7837L72.2157 75.0071C72.2642 75.0018 72.3112 74.987 72.354 74.9635C72.3968 74.94 72.4345 74.9083 72.465 74.8702C72.4954 74.8321 72.5181 74.7884 72.5316 74.7415C72.5451 74.6946 72.5493 74.6455 72.5437 74.5971L72.1597 71.22C72.148 71.1225 72.0985 71.0335 72.0218 70.9721C71.9451 70.9108 71.8474 70.882 71.7497 70.892L56.1888 72.6755Z"
      fill="#161616"
    />
    <path
      d="M93.633 35.2773L95.4603 45.7815L90.2396 50.6387L93.633 67.4487L88.2866 79.5259L89.7216 96.7268L132.727 101.812C133.716 101.923 134.71 101.637 135.488 101.016C136.267 100.396 136.768 99.4916 136.881 98.5021L143.179 44.1812C143.238 43.6877 143.199 43.1873 143.064 42.709C142.929 42.2307 142.7 41.7837 142.392 41.3939C142.084 41.0041 141.701 40.6791 141.267 40.4377C140.832 40.1962 140.354 40.043 139.861 39.9869L93.633 35.2773Z"
      fill="#161616"
    />
    <path
      d="M91.5447 35.1484L93.3719 45.5898L88.1499 50.4182L91.5447 67.1216L86.1942 79.1278L87.6292 96.2316L130.634 101.152C131.621 101.264 132.612 100.982 133.391 100.365C134.17 99.7484 134.672 98.8484 134.789 97.862L141.086 44.3871C141.144 43.8951 141.104 43.3965 140.969 42.92C140.833 42.4436 140.604 41.9987 140.296 41.6111C139.987 41.2234 139.605 40.9007 139.171 40.6615C138.737 40.4223 138.261 40.2714 137.768 40.2174L91.5447 35.1484Z"
      fill="#CCCCCC"
    />
    <path
      d="M131.063 101.585C130.904 101.585 130.745 101.575 130.587 101.558L87.249 96.5938L85.7758 79.0581L91.1167 67.0752L87.7 50.272L92.933 45.4381L91.047 34.6797L137.813 39.8088C138.359 39.8687 138.888 40.0361 139.369 40.3015C139.85 40.5668 140.274 40.9247 140.616 41.3547C140.959 41.7846 141.212 42.278 141.363 42.8064C141.513 43.3349 141.558 43.8879 141.493 44.4336L135.196 97.9085C135.075 98.9203 134.587 99.8527 133.826 100.53C133.065 101.207 132.082 101.582 131.063 101.585ZM88.013 95.8558L130.682 100.74C131.56 100.839 132.442 100.586 133.135 100.037C133.828 99.4881 134.276 98.6877 134.381 97.8101L140.679 44.3352C140.73 43.897 140.695 43.453 140.574 43.0286C140.453 42.6043 140.249 42.2081 139.975 41.8628C139.7 41.5175 139.36 41.23 138.974 41.0168C138.587 40.8036 138.163 40.669 137.724 40.6206L92.0419 35.6145L93.8186 45.7374L88.6034 50.5617L91.9763 67.1681L86.6149 79.1948L88.013 95.8558Z"
      fill="#161616"
    />
    <path
      d="M91.926 70.4262L91.5301 73.7965C91.4796 74.226 91.7869 74.6151 92.2165 74.6656L107.359 76.4444C107.788 76.4949 108.178 76.1876 108.228 75.758L108.624 72.3878C108.674 71.9582 108.367 71.5691 107.938 71.5186L92.7951 69.7398C92.3656 69.6894 91.9765 69.9967 91.926 70.4262Z"
      fill="white"
    />
    <path
      d="M107.458 76.869C107.412 76.8688 107.367 76.866 107.321 76.8608L92.1788 75.0841C91.8649 75.0464 91.5786 74.886 91.3827 74.6378C91.1867 74.3896 91.0971 74.0739 91.1333 73.7598L91.5296 70.3896C91.5666 70.0754 91.7269 69.7887 91.9753 69.5926C92.2236 69.3965 92.5397 69.3071 92.8539 69.3441L107.997 71.1208C108.311 71.1581 108.597 71.3185 108.793 71.5668C108.989 71.815 109.079 72.1309 109.042 72.4451L108.646 75.8153C108.611 76.1059 108.471 76.3738 108.252 76.568C108.033 76.7622 107.751 76.8693 107.458 76.869ZM92.7091 70.1532C92.6179 70.1533 92.5299 70.1869 92.4618 70.2476C92.3938 70.3083 92.3505 70.3919 92.3401 70.4825L91.9437 73.8527C91.9333 73.9503 91.9618 74.048 92.0229 74.1248C92.084 74.2015 92.173 74.2511 92.2704 74.2627L107.413 76.0394C107.511 76.0494 107.608 76.0209 107.685 75.9598C107.761 75.8987 107.811 75.81 107.823 75.7128L108.219 72.3426C108.229 72.2451 108.201 72.1475 108.14 72.0709C108.079 71.9942 107.99 71.9446 107.893 71.9326L92.7487 70.1559C92.7356 70.1538 92.7223 70.1529 92.7091 70.1532Z"
      fill="#161616"
    />
    <path
      d="M113.327 72.7778L112.931 76.148C112.881 76.5776 113.188 76.9667 113.617 77.0171L128.76 78.796C129.189 78.8464 129.579 78.5391 129.629 78.1096L130.025 74.7393C130.075 74.3098 129.768 73.9207 129.339 73.8702L114.196 72.0914C113.767 72.0409 113.377 72.3482 113.327 72.7778Z"
      fill="white"
    />
    <path
      d="M128.86 79.2187C128.814 79.2186 128.769 79.2159 128.723 79.2105L113.581 77.4339C113.425 77.4157 113.275 77.3671 113.138 77.2908C113.001 77.2145 112.881 77.1119 112.784 76.9891C112.687 76.8662 112.615 76.7254 112.572 76.5747C112.53 76.4241 112.517 76.2665 112.535 76.1109L112.93 72.7407C112.968 72.4269 113.129 72.1409 113.377 71.945C113.625 71.7492 113.94 71.6594 114.255 71.6952L129.399 73.4719C129.713 73.5089 130 73.6692 130.196 73.9175C130.392 74.1659 130.481 74.4819 130.444 74.7962L130.048 78.1664C130.013 78.4567 129.872 78.724 129.654 78.9179C129.435 79.1118 129.152 79.2188 128.86 79.2187ZM114.116 72.5029C114.025 72.5033 113.937 72.5369 113.869 72.5976C113.801 72.6582 113.757 72.7417 113.746 72.8323L113.35 76.2025C113.339 76.3003 113.368 76.3982 113.429 76.475C113.491 76.5517 113.58 76.6012 113.678 76.6125L128.82 78.3892C128.918 78.3992 129.015 78.3706 129.092 78.3096C129.169 78.2485 129.218 78.1598 129.23 78.0625L129.627 74.6923C129.637 74.5948 129.608 74.4973 129.547 74.4206C129.486 74.344 129.397 74.2943 129.3 74.2823L114.156 72.5057C114.143 72.5036 114.13 72.5027 114.116 72.5029Z"
      fill="#161616"
    />
    <path
      d="M124.824 97.5289C128.212 97.5289 130.958 94.7828 130.958 91.3953C130.958 88.0078 128.212 85.2617 124.824 85.2617C121.437 85.2617 118.691 88.0078 118.691 91.3953C118.691 94.7828 121.437 97.5289 124.824 97.5289Z"
      fill="white"
    />
    <path
      d="M124.824 97.9415C123.53 97.9418 122.264 97.5581 121.188 96.839C120.111 96.12 119.272 95.0978 118.777 93.9018C118.281 92.7058 118.151 91.3897 118.404 90.12C118.656 88.8503 119.28 87.684 120.195 86.7685C121.11 85.8531 122.277 85.2298 123.547 84.9773C124.816 84.7248 126.132 84.8545 127.328 85.35C128.524 85.8456 129.546 86.6847 130.266 87.7612C130.985 88.8377 131.368 90.1033 131.368 91.3979C131.366 93.1327 130.676 94.7958 129.449 96.0225C128.222 97.2492 126.559 97.9393 124.824 97.9415ZM124.824 85.6729C123.692 85.6727 122.585 86.0082 121.643 86.6371C120.702 87.266 119.968 88.16 119.534 89.2061C119.101 90.2522 118.987 91.4033 119.208 92.5139C119.429 93.6246 119.974 94.6448 120.774 95.4456C121.575 96.2464 122.595 96.7917 123.706 97.0128C124.816 97.2338 125.967 97.1205 127.014 96.6872C128.06 96.2539 128.954 95.5202 129.583 94.5787C130.212 93.6372 130.548 92.5302 130.548 91.3979C130.546 89.8803 129.943 88.4254 128.87 87.3522C127.797 86.279 126.342 85.6751 124.824 85.6729Z"
      fill="#161616"
    />
    <path
      d="M115.688 96.3531C119.076 96.3531 121.822 93.607 121.822 90.2195C121.822 86.832 119.076 84.0859 115.688 84.0859C112.301 84.0859 109.554 86.832 109.554 90.2195C109.554 93.607 112.301 96.3531 115.688 96.3531Z"
      fill="#161616"
    />
  </svg>
);

const NotFoundSvg = () => (
  <svg
    width="204"
    height="202"
    viewBox="0 0 204 202"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22138_1220)">
      <path
        d="M199.128 120.904C198.9 127.376 198.103 133.908 196.439 140.302C196.394 140.476 196.349 140.647 196.305 140.821C196.121 141.519 195.938 142.212 195.738 142.905V142.907C195.287 144.398 194.787 145.878 194.231 147.348C191.894 153.544 188.613 159.536 184.093 165.137C174.358 177.197 158.879 187.433 134.728 193.957C120.43 197.821 103.774 199.719 88.9382 198.547C80.8793 197.911 72.6765 196.165 64.6205 193.657C50.8473 189.365 37.4987 182.839 26.0255 175.788C13.4395 168.054 8.42548 157.619 6.65244 145.823C6.64027 145.734 6.62523 145.644 6.61306 145.553C6.22279 142.878 5.99436 140.136 5.88265 137.338C5.69145 132.587 5.82536 127.685 6.04449 122.7C6.22064 118.672 6.12397 114.634 5.81104 110.606C5.76879 110.058 5.72153 109.506 5.66926 108.957C5.17014 103.556 4.29866 98.1712 3.18586 92.8543C2.76122 90.8127 2.30149 88.7812 1.8124 86.7625C-2.55574 68.6956 1.04261 49.7443 11.8205 34.2031C22.599 18.6618 39.6305 7.84885 59.0558 4.21613C91.7825 -1.90284 124.983 2.13518 124.983 2.13518C167.376 6.53841 189.488 47.475 195.718 82.1688C195.915 83.2616 196.096 84.3493 196.26 85.427C197.887 96.076 199.57 108.376 199.128 120.905V120.904Z"
        fill="#F4F7FA"
      />
      <path
        d="M194.231 147.35C191.894 153.546 188.613 159.538 184.093 165.139C174.358 177.198 158.879 187.435 134.728 193.959C120.43 197.823 103.774 199.721 88.9381 198.548C88.598 198.521 88.255 198.494 87.9148 198.462C87.5718 198.432 87.2295 198.397 86.8894 198.362C86.4869 198.322 86.0845 198.278 85.6821 198.23C85.3992 198.201 85.1156 198.166 84.8328 198.131C84.5771 198.099 84.3186 198.066 84.063 198.031C83.9191 198.014 83.7751 197.994 83.6312 197.974C83.3777 197.939 83.1249 197.905 82.8714 197.867C82.7124 197.845 82.5513 197.82 82.3895 197.795C82.0293 197.743 81.6691 197.688 81.3118 197.629C81.0712 197.592 80.8298 197.552 80.5892 197.512C80.4897 197.495 80.388 197.48 80.2885 197.46C80.0873 197.428 79.8839 197.393 79.6798 197.355C78.4582 197.142 77.2365 196.904 76.017 196.645C75.3367 196.503 74.6586 196.352 73.9783 196.195C73.5759 196.104 73.1734 196.009 72.7739 195.913C72.5855 195.868 72.3965 195.821 72.2074 195.774C70.226 195.287 68.2489 194.748 66.2797 194.162C65.9789 194.072 65.676 193.983 65.3759 193.891C65.1253 193.814 64.8718 193.737 64.6212 193.658C50.8479 189.366 37.4993 182.84 26.0262 175.789C13.4402 168.055 8.42615 157.62 6.65311 145.824C6.64094 145.735 6.6259 145.645 6.61373 145.554C6.22346 142.879 5.99503 140.137 5.88332 137.339C5.69212 132.588 5.82603 127.686 6.04515 122.701C6.22131 118.673 6.12464 114.635 5.81171 110.607C5.76946 110.059 5.7222 109.507 5.66992 108.958C24.9212 107.957 59.9945 110.369 89.0384 135.697C90.2328 136.737 91.4172 137.82 92.5895 138.94C125.262 170.164 161.401 142.645 194.231 147.349V147.35Z"
        fill="#D8DEE8"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
        <path
          d="M194.231 147.348C191.894 153.545 188.613 159.537 184.093 165.137C174.358 177.197 158.879 187.433 134.728 193.958C120.43 197.822 103.774 199.719 88.938 198.547C88.5979 198.52 88.2549 198.493 87.9147 198.46C87.5717 198.43 87.2294 198.396 86.8893 198.361C86.4869 198.321 86.0844 198.276 85.682 198.229C85.3991 198.2 85.1156 198.165 84.8327 198.13C84.5771 198.097 84.3185 198.065 84.0629 198.03C83.919 198.013 83.775 197.993 83.6311 197.973C83.3776 197.938 83.1248 197.903 82.8713 197.866C82.7124 197.844 82.5512 197.819 82.3894 197.794C82.0292 197.742 81.669 197.687 81.3117 197.628C81.0711 197.59 80.8298 197.55 80.5892 197.511C80.4896 197.494 80.3879 197.479 80.2884 197.459C80.0872 197.426 79.8838 197.391 79.6797 197.354C78.4581 197.141 77.2364 196.902 76.0169 196.644C75.3366 196.5 74.6585 196.351 73.9782 196.194C73.5758 196.102 73.1733 196.008 72.7738 195.911C72.5854 195.867 72.3964 195.82 72.2073 195.772C70.2259 195.285 68.2488 194.747 66.2796 194.16C65.9788 194.071 65.6759 193.981 65.3759 193.89C65.1252 193.812 64.8717 193.736 64.6211 193.656C66.3841 191.486 71.2986 189.574 80.3013 188.074C131.709 179.506 107.488 170.938 84.3937 159.762C66.4507 151.083 66.7192 145.999 89.0376 135.695C90.232 136.736 91.4164 137.819 92.5886 138.938C125.261 170.163 161.4 142.644 194.23 147.348L194.231 147.348Z"
          fill="#D8DEE8"
        />
      </g>
      <path
        d="M163.702 135.141V135.146C163.702 135.23 163.566 136.273 163.208 137.771C163.109 138.18 162.978 138.628 162.841 139.092C162.841 139.092 162.84 139.094 162.838 139.097C162.173 141.332 160.973 144.089 158.884 146.284C154.698 150.67 150.53 192.509 150.53 192.509H142.593C142.593 192.509 142.586 192.496 142.568 192.322C142.21 188.463 137.976 150.509 134 146.352C131.926 144.181 130.667 141.412 129.955 139.169C129.801 138.677 129.612 138.21 129.508 137.781C129.152 136.278 128.934 135.235 128.934 135.146V135.141L146.246 135.227L163.702 135.141Z"
        fill="#97A6B7"
      />
      <path
        d="M146.555 193.368C148.75 193.368 150.53 192.949 150.53 192.432C150.53 191.915 148.75 191.496 146.555 191.496C144.36 191.496 142.58 191.915 142.58 192.432C142.58 192.949 144.36 193.368 146.555 193.368Z"
        fill="#97A6B7"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M163.702 135.141C163.702 135.141 163.566 136.271 163.208 137.768C163.109 138.178 162.978 138.626 162.841 139.091C162.834 139.091 162.829 139.093 162.821 139.096C158.107 140.519 152.405 141.349 146.269 141.349C140.132 141.349 134.624 140.547 129.955 139.169C129.801 138.677 129.669 138.21 129.565 137.78C129.208 136.278 129.058 135.234 129.046 135.146L146.194 135.26L163.635 135.141H163.702V135.141Z"
          fill="#D8DEE8"
        />
      </g>
      <path
        d="M146.318 138.744C171.762 138.744 192.389 118.117 192.389 92.6725C192.389 67.2282 171.762 46.6016 146.318 46.6016C120.874 46.6016 100.247 67.2282 100.247 92.6725C100.247 118.117 120.874 138.744 146.318 138.744Z"
        fill="white"
      />
      <mask
        id="mask0_22138_1220"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="100"
        y="46"
        width="93"
        height="93"
      >
        <path
          d="M146.318 138.744C171.762 138.744 192.389 118.117 192.389 92.6725C192.389 67.2282 171.762 46.6016 146.318 46.6016C120.874 46.6016 100.247 67.2282 100.247 92.6725C100.247 118.117 120.874 138.744 146.318 138.744Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_22138_1220)">
        <path
          d="M104.888 54.7234C104.084 56.5509 102 58.4421 100.151 58.9949C98.3046 59.5456 97.5011 58.6512 98.4542 56.9498C99.4059 55.2498 101.632 53.2769 103.329 52.5988C105.029 51.9207 105.689 52.8967 104.888 54.7234Z"
          fill="url(#paint0_linear_22138_1220)"
        />
        <path
          d="M114.35 52.0302C113.73 53.9851 111.781 55.8856 109.932 56.3217C108.086 56.7557 107.147 55.6422 107.916 53.7882C108.684 51.935 110.744 49.9521 112.442 49.4172C114.141 48.8816 114.966 50.0774 114.35 52.0302Z"
          fill="url(#paint1_linear_22138_1220)"
        />
        <path
          d="M123.811 50.0227C123.376 52.0714 121.562 53.9533 119.713 54.2741C117.867 54.592 116.794 53.2873 117.377 51.3116C117.959 49.3381 119.856 47.3732 121.554 46.9807C123.253 46.5876 124.242 47.9754 123.811 50.0227Z"
          fill="url(#paint2_linear_22138_1220)"
        />
        <path
          d="M133.273 48.7074C133.023 50.817 131.343 52.6545 129.495 52.8586C127.648 53.0612 126.441 51.5904 126.838 49.5273C127.236 47.4657 128.968 45.5459 130.666 45.296C132.365 45.046 133.52 46.5985 133.272 48.7067L133.273 48.7074Z"
          fill="url(#paint3_linear_22138_1220)"
        />
        <path
          d="M142.734 48.0804C142.668 50.2179 141.125 51.9838 139.276 52.0719C137.429 52.1578 136.087 50.5495 136.3 48.432C136.514 46.3152 138.08 44.467 139.778 44.3603C141.477 44.2529 142.797 45.9443 142.734 48.0804Z"
          fill="url(#paint4_linear_22138_1220)"
        />
        <path
          d="M152.195 48.1417C152.316 50.2735 150.906 51.942 149.059 51.914C147.211 51.884 145.734 50.1639 145.762 48.0243C145.791 45.8867 147.194 44.1366 148.891 44.1724C150.59 44.2075 152.074 46.0113 152.195 48.1417Z"
          fill="url(#paint5_linear_22138_1220)"
        />
        <path
          d="M161.656 48.8945C161.962 50.9883 160.686 52.5322 158.84 52.3875C156.992 52.2415 155.381 50.4362 155.223 48.3087C155.068 46.1819 156.305 44.5571 158.004 44.7361C159.702 44.9145 161.351 46.8021 161.657 48.8952L161.656 48.8945Z"
          fill="url(#paint6_linear_22138_1220)"
        />
        <path
          d="M171.118 50.3327C171.608 52.3564 170.468 53.747 168.621 53.4871C166.774 53.225 165.027 51.3618 164.684 49.2779C164.344 47.1963 165.417 45.7226 167.116 46.0441C168.813 46.3656 170.628 48.3112 171.118 50.3327Z"
          fill="url(#paint7_linear_22138_1220)"
        />
        <path
          d="M180.579 52.4644C181.254 54.3828 180.248 55.5966 178.403 55.2199C176.555 54.8419 174.673 52.9471 174.146 50.9413C173.622 48.937 174.529 47.6416 176.228 48.1063C177.925 48.5703 179.905 50.5468 180.58 52.4652L180.579 52.4644Z"
          fill="url(#paint8_linear_22138_1220)"
        />
        <path
          d="M190.041 55.2832C190.901 57.0648 190.031 58.0723 188.184 57.5796C186.336 57.0855 184.32 55.1858 183.607 53.291C182.898 51.3969 183.641 50.3078 185.34 50.9143C187.037 51.5208 189.181 53.5022 190.041 55.2832Z"
          fill="url(#paint9_linear_22138_1220)"
        />
        <path
          d="M106.32 63.1354C105.785 65.0302 103.799 66.8283 101.811 67.1856C99.8254 67.5401 98.6667 66.4116 99.3119 64.6299C99.9564 62.849 102.07 60.9893 103.944 60.5203C105.821 60.0513 106.851 61.2414 106.32 63.1362V63.1354Z"
          fill="url(#paint10_linear_22138_1220)"
        />
        <path
          d="M116.625 61.3741C116.229 63.3798 114.341 65.1994 112.352 65.4722C110.366 65.7436 109.11 64.4354 109.617 62.5162C110.123 60.5986 112.11 58.7167 113.984 58.3579C115.86 57.9992 117.017 59.369 116.625 61.3734V61.3741Z"
          fill="url(#paint11_linear_22138_1220)"
        />
        <path
          d="M126.93 60.1251C126.673 62.2082 124.881 64.022 122.894 64.2118C120.907 64.3994 119.555 62.9386 119.923 60.9157C120.291 58.8934 122.149 57.0173 124.024 56.7688C125.9 56.5203 127.184 58.042 126.93 60.1244V60.1251Z"
          fill="url(#paint12_linear_22138_1220)"
        />
        <path
          d="M137.235 59.3976C137.117 61.5251 135.424 63.3081 133.436 63.4134C131.449 63.5172 129.999 61.9311 130.228 59.8373C130.457 57.7441 132.188 55.8995 134.063 55.762C135.939 55.6238 137.351 57.2715 137.235 59.3983V59.3976Z"
          fill="url(#paint13_linear_22138_1220)"
        />
        <path
          d="M147.541 59.1853C147.562 61.325 145.965 63.0486 143.977 63.0701C141.99 63.0894 140.442 61.4052 140.533 59.2734C140.624 57.1423 142.228 55.3557 144.104 55.3285C145.979 55.3005 147.518 57.0478 147.541 59.1853Z"
          fill="url(#paint14_linear_22138_1220)"
        />
        <path
          d="M157.845 59.4889C158.006 61.6064 156.505 63.2448 154.519 63.1832C152.531 63.1195 150.886 61.3629 150.838 59.2254C150.79 57.0886 152.266 55.3886 154.142 55.4717C156.018 55.5547 157.685 57.3729 157.845 59.4889Z"
          fill="url(#paint15_linear_22138_1220)"
        />
        <path
          d="M168.151 60.3139C168.45 62.3769 167.048 63.9029 165.061 63.7575C163.073 63.61 161.331 61.8076 161.143 59.6987C160.958 57.5899 162.308 56.0023 164.183 56.1956C166.057 56.389 167.853 58.2522 168.151 60.3146V60.3139Z"
          fill="url(#paint16_linear_22138_1220)"
        />
        <path
          d="M178.455 61.6531C178.893 63.6281 177.588 65.0159 175.602 64.7867C173.614 64.5554 171.774 62.7351 171.448 60.6864C171.123 58.6384 172.345 57.1897 174.222 57.4934C176.096 57.797 178.018 59.6789 178.455 61.6531Z"
          fill="url(#paint17_linear_22138_1220)"
        />
        <path
          d="M188.761 63.5079C189.337 65.3618 188.13 66.5842 186.143 66.2713C184.155 65.9569 182.218 64.1438 181.753 62.1896C181.291 60.2361 182.385 58.9528 184.261 59.3667C186.136 59.7806 188.186 61.6546 188.761 63.5079Z"
          fill="url(#paint18_linear_22138_1220)"
        />
        <path
          d="M199.066 65.8783C199.781 67.579 198.671 68.6081 196.685 68.2113C194.697 67.8125 192.662 66.0351 192.058 64.2084C191.458 62.3817 192.425 61.2911 194.301 61.8152C196.174 62.3394 198.352 64.1783 199.066 65.8783Z"
          fill="url(#paint19_linear_22138_1220)"
        />
        <path
          d="M109.417 72.3531C109.106 74.3073 107.218 76.0317 105.135 76.225C103.055 76.4169 101.616 75.0864 102 73.2325C102.383 71.3793 104.384 69.6134 106.39 69.3205C108.398 69.0283 109.725 70.3996 109.417 72.3531Z"
          fill="url(#paint20_linear_22138_1220)"
        />
        <path
          d="M120.324 71.3481C120.107 73.3969 118.278 75.1549 116.195 75.2974C114.114 75.4384 112.615 73.9676 112.907 71.9933C113.198 70.0191 115.109 68.2188 117.115 68.0047C119.123 67.7899 120.539 69.3016 120.324 71.3489V71.3481Z"
          fill="url(#paint21_linear_22138_1220)"
        />
        <path
          d="M131.233 70.6933C131.107 72.8022 129.339 74.5695 127.257 74.6597C125.176 74.7492 123.616 73.1659 123.815 71.1036C124.015 69.0413 125.836 67.2346 127.842 67.0978C129.849 66.9618 131.356 68.5851 131.233 70.694V70.6933Z"
          fill="url(#paint22_linear_22138_1220)"
        />
        <path
          d="M142.141 70.3787C142.108 72.5162 140.399 74.2628 138.318 74.3029C136.236 74.3408 134.617 72.6716 134.724 70.5549C134.831 68.4381 136.562 66.6493 138.569 66.5913C140.576 66.5326 142.173 68.2426 142.142 70.3794L142.141 70.3787Z"
          fill="url(#paint23_linear_22138_1220)"
        />
        <path
          d="M153.049 70.4083C153.109 72.5401 151.46 74.2422 149.379 74.2301C147.297 74.2165 145.618 72.4885 145.632 70.3496C145.647 68.2121 147.288 66.4691 149.295 66.4884C151.303 66.5071 152.988 68.2772 153.05 70.4083H153.049Z"
          fill="url(#paint24_linear_22138_1220)"
        />
        <path
          d="M163.957 70.7821C164.109 72.876 162.519 74.5051 160.439 74.4413C158.357 74.3762 156.618 72.616 156.539 70.4885C156.461 68.3617 158.013 66.6918 160.021 66.7892C162.027 66.8866 163.804 68.6883 163.957 70.7814V70.7821Z"
          fill="url(#paint25_linear_22138_1220)"
        />
        <path
          d="M174.865 71.5021C175.11 73.5251 173.581 75.0546 171.5 74.9401C169.417 74.8241 167.619 73.0575 167.447 70.9751C167.278 68.8927 168.74 67.3223 170.747 67.4978C172.754 67.6732 174.62 69.4806 174.865 71.5028V71.5021Z"
          fill="url(#paint26_linear_22138_1220)"
        />
        <path
          d="M185.773 72.5687C186.11 74.4871 184.641 75.8914 182.56 75.7245C180.479 75.557 178.62 73.8118 178.355 71.8068C178.093 69.8025 179.464 68.3574 181.473 68.6109C183.48 68.8651 185.435 70.6503 185.772 72.5687H185.773Z"
          fill="url(#paint27_linear_22138_1220)"
        />
        <path
          d="M196.681 73.9805C197.111 75.7621 195.703 77.0131 193.622 76.7954C191.539 76.577 189.62 74.8792 189.264 72.9844C188.91 71.0903 190.191 69.7985 192.2 70.1301C194.205 70.4616 196.252 72.1996 196.681 73.9805Z"
          fill="url(#paint28_linear_22138_1220)"
        />
        <path
          d="M102.548 82.8263C102.369 84.7211 100.555 86.3158 98.4229 86.3981C96.2925 86.4783 94.6706 85.1056 94.8847 83.3247C95.0995 81.543 97.0409 79.9275 99.134 79.7328C101.23 79.5394 102.724 80.9322 102.548 82.8263Z"
          fill="url(#paint29_linear_22138_1220)"
        />
        <path
          d="M113.818 82.2418C113.684 84.2469 111.893 85.9168 109.761 85.9791C107.631 86.0399 105.985 84.5412 106.154 82.6228C106.323 80.7044 108.211 79.0144 110.304 78.8655C112.398 78.7172 113.947 80.2375 113.817 82.2418H113.818Z"
          fill="url(#paint30_linear_22138_1220)"
        />
        <path
          d="M125.087 81.8231C125.001 83.9055 123.231 85.6227 121.1 85.6664C118.97 85.7079 117.301 84.1096 117.424 82.0866C117.546 80.0644 119.384 78.3265 121.476 78.2234C123.57 78.1209 125.172 79.7407 125.087 81.8231H125.087Z"
          fill="url(#paint31_linear_22138_1220)"
        />
        <path
          d="M136.357 81.5809C136.317 83.7084 134.57 85.4456 132.439 85.4699C130.309 85.4929 128.617 83.8208 128.694 81.727C128.771 79.6331 130.555 77.8751 132.648 77.8178C134.742 77.7605 136.395 79.4541 136.357 81.5801V81.5809Z"
          fill="url(#paint32_linear_22138_1220)"
        />
        <path
          d="M147.627 81.5107C147.634 83.6497 145.909 85.3805 143.778 85.3862C141.647 85.3905 139.934 83.6719 139.964 81.5401C139.995 79.409 141.727 77.656 143.82 77.6446C145.914 77.6338 147.619 79.3718 147.627 81.5107Z"
          fill="url(#paint33_linear_22138_1220)"
        />
        <path
          d="M158.897 81.6135C158.95 83.731 157.247 85.4295 155.117 85.4152C152.986 85.4002 151.249 83.6622 151.234 81.5254C151.218 79.3893 152.899 77.6693 154.992 77.7036C157.085 77.738 158.844 79.4967 158.898 81.6135H158.897Z"
          fill="url(#paint34_linear_22138_1220)"
        />
        <path
          d="M170.167 81.8851C170.266 83.9474 168.586 85.5866 166.456 85.5536C164.325 85.52 162.566 83.7892 162.503 81.6796C162.442 79.5707 164.069 77.9115 166.163 77.991C168.256 78.0712 170.066 79.8221 170.167 81.8844V81.8851Z"
          fill="url(#paint35_linear_22138_1220)"
        />
        <path
          d="M181.436 82.3323C181.582 84.308 179.925 85.8597 177.795 85.8075C175.663 85.7545 173.882 84.0573 173.773 82.01C173.666 79.962 175.241 78.3895 177.335 78.5148C179.427 78.6408 181.291 80.358 181.437 82.3323H181.436Z"
          fill="url(#paint36_linear_22138_1220)"
        />
        <path
          d="M192.706 82.9497C192.898 84.8036 191.263 86.243 189.133 86.1721C187.002 86.1005 185.198 84.4635 185.042 82.51C184.889 80.5558 186.412 79.0964 188.506 79.2675C190.598 79.4394 192.515 81.0964 192.706 82.9497Z"
          fill="url(#paint37_linear_22138_1220)"
        />
        <path
          d="M107.772 92.6722C107.771 94.6256 106.089 96.1738 103.954 96.1244C101.82 96.0736 100.027 94.5261 100.027 92.6722C100.027 90.8182 101.82 89.27 103.954 89.2199C106.09 89.1705 107.771 90.7187 107.772 92.6722Z"
          fill="url(#paint38_linear_22138_1220)"
        />
        <path
          d="M119.162 92.6704C119.161 94.7177 117.464 96.3532 115.33 96.3167C113.195 96.2795 111.418 94.6446 111.417 92.6704C111.416 90.6961 113.195 89.0606 115.33 89.024C117.465 88.9875 119.161 90.6231 119.162 92.6704Z"
          fill="url(#paint39_linear_22138_1220)"
        />
        <path
          d="M130.553 92.6718C130.552 94.7807 128.841 96.4764 126.706 96.4528C124.572 96.4291 122.807 94.7342 122.807 92.6718C122.807 90.6095 124.571 88.9145 126.706 88.8909C128.841 88.8672 130.552 90.5629 130.553 92.6718Z"
          fill="url(#paint40_linear_22138_1220)"
        />
        <path
          d="M141.943 92.6726C141.942 94.8094 140.216 96.538 138.081 96.5287C135.947 96.518 134.197 94.7893 134.198 92.6726C134.198 90.5558 135.946 88.8272 138.081 88.8164C140.216 88.8071 141.942 90.5351 141.943 92.6726Z"
          fill="url(#paint41_linear_22138_1220)"
        />
        <path
          d="M153.333 92.6712C153.333 94.803 151.592 96.5388 149.457 96.5424C147.322 96.5453 145.589 94.8102 145.588 92.6712C145.589 90.533 147.322 88.7972 149.457 88.8008C151.592 88.8044 153.333 90.5395 153.333 92.6712Z"
          fill="url(#paint42_linear_22138_1220)"
        />
        <path
          d="M164.723 92.6699C164.723 94.7631 162.967 96.4788 160.833 96.496C158.697 96.5118 156.979 94.7967 156.978 92.6699C156.979 90.5431 158.697 88.8274 160.833 88.8439C162.967 88.8611 164.723 90.5761 164.723 92.6699Z"
          fill="url(#paint43_linear_22138_1220)"
        />
        <path
          d="M176.113 92.6711C176.113 94.6933 174.342 96.3618 172.208 96.3926C170.072 96.422 168.37 94.7535 168.368 92.6711C168.37 90.5887 170.072 88.9209 172.208 88.9496C174.342 88.9797 176.113 90.6481 176.113 92.6711Z"
          fill="url(#paint44_linear_22138_1220)"
        />
        <path
          d="M187.504 92.6709C187.504 94.5893 185.718 96.1834 183.584 96.2278C181.449 96.2707 179.76 94.676 179.759 92.6709C179.76 90.6659 181.448 89.0712 183.584 89.1141C185.718 89.1578 187.505 90.7525 187.504 92.6709Z"
          fill="url(#paint45_linear_22138_1220)"
        />
        <path
          d="M198.894 92.6702C198.894 94.4518 197.094 95.9456 194.96 96.0028C192.823 96.0587 191.15 94.5642 191.149 92.6702C191.15 90.7754 192.824 89.2809 194.96 89.3375C197.093 89.3948 198.895 90.8885 198.894 92.6702Z"
          fill="url(#paint46_linear_22138_1220)"
        />
        <path
          d="M102.548 102.517C102.724 104.411 101.227 105.804 99.1336 105.61C97.0405 105.416 95.0999 103.799 94.8843 102.018C94.6695 100.237 96.2922 98.8639 98.4225 98.9448C100.554 99.0257 102.369 100.622 102.548 102.517Z"
          fill="url(#paint47_linear_22138_1220)"
        />
        <path
          d="M113.818 103.102C113.948 105.107 112.399 106.627 110.305 106.479C108.213 106.33 106.323 104.64 106.155 102.721C105.986 100.803 107.632 99.3035 109.762 99.3651C111.894 99.4274 113.685 101.097 113.819 103.102H113.818Z"
          fill="url(#paint48_linear_22138_1220)"
        />
        <path
          d="M125.088 103.52C125.172 105.602 123.57 107.222 121.477 107.12C119.384 107.017 117.547 105.279 117.425 103.256C117.302 101.233 118.971 99.6344 121.101 99.6766C123.233 99.7196 125.002 101.437 125.088 103.52H125.088Z"
          fill="url(#paint49_linear_22138_1220)"
        />
        <path
          d="M136.357 103.761C136.395 105.888 134.742 107.581 132.648 107.523C130.555 107.466 128.771 105.707 128.694 103.614C128.617 101.52 130.309 99.8484 132.439 99.8713C134.571 99.8957 136.317 101.634 136.357 103.76V103.761Z"
          fill="url(#paint50_linear_22138_1220)"
        />
        <path
          d="M147.627 103.833C147.619 105.971 145.914 107.709 143.82 107.699C141.728 107.687 139.994 105.935 139.964 103.803C139.934 101.671 141.647 99.9527 143.778 99.957C145.909 99.9628 147.634 101.694 147.627 103.833Z"
          fill="url(#paint51_linear_22138_1220)"
        />
        <path
          d="M158.897 103.731C158.843 105.848 157.085 107.607 154.992 107.641C152.898 107.675 151.218 105.956 151.233 103.82C151.25 101.682 152.985 99.9448 155.117 99.9298C157.248 99.9162 158.949 101.615 158.896 103.731H158.897Z"
          fill="url(#paint52_linear_22138_1220)"
        />
        <path
          d="M170.167 103.459C170.067 105.521 168.257 107.272 166.163 107.352C164.069 107.432 162.442 105.772 162.503 103.664C162.565 101.554 164.325 99.8239 166.456 99.7896C168.586 99.7566 170.266 101.396 170.167 103.458V103.459Z"
          fill="url(#paint53_linear_22138_1220)"
        />
        <path
          d="M181.436 103.012C181.29 104.986 179.427 106.703 177.334 106.829C175.24 106.954 173.665 105.381 173.772 103.334C173.882 101.286 175.663 99.5887 177.794 99.5364C179.923 99.4848 181.581 101.037 181.435 103.012H181.436Z"
          fill="url(#paint54_linear_22138_1220)"
        />
        <path
          d="M192.706 102.393C192.515 104.247 190.598 105.903 188.506 106.075C186.412 106.247 184.889 104.785 185.042 102.833C185.198 100.878 187.002 99.2428 189.133 99.1704C191.264 99.1003 192.898 100.539 192.706 102.393Z"
          fill="url(#paint55_linear_22138_1220)"
        />
        <path
          d="M109.417 112.996C109.725 114.949 108.397 116.321 106.39 116.029C104.384 115.736 102.383 113.97 102 112.117C101.616 110.263 103.055 108.931 105.135 109.124C107.218 109.317 109.107 111.042 109.417 112.996Z"
          fill="url(#paint56_linear_22138_1220)"
        />
        <path
          d="M120.324 113.997C120.54 116.044 119.123 117.556 117.115 117.341C115.109 117.126 113.198 115.327 112.907 113.352C112.615 111.377 114.115 109.907 116.195 110.048C118.278 110.191 120.106 111.95 120.324 113.998V113.997Z"
          fill="url(#paint57_linear_22138_1220)"
        />
        <path
          d="M131.233 114.657C131.356 116.766 129.85 118.389 127.842 118.253C125.835 118.116 124.014 116.309 123.815 114.247C123.617 112.185 125.175 110.602 127.257 110.691C129.339 110.781 131.107 112.549 131.233 114.658V114.657Z"
          fill="url(#paint58_linear_22138_1220)"
        />
        <path
          d="M142.141 114.967C142.172 117.103 140.576 118.814 138.568 118.755C136.561 118.697 134.829 116.908 134.723 114.792C134.617 112.674 136.235 111.006 138.317 111.044C140.399 111.083 142.108 112.83 142.14 114.968L142.141 114.967Z"
          fill="url(#paint59_linear_22138_1220)"
        />
        <path
          d="M153.049 114.935C152.988 117.067 151.301 118.836 149.294 118.855C147.287 118.874 145.646 117.132 145.631 114.994C145.618 112.856 147.296 111.127 149.378 111.113C151.458 111.101 153.109 112.803 153.048 114.935H153.049Z"
          fill="url(#paint60_linear_22138_1220)"
        />
        <path
          d="M163.956 114.56C163.804 116.653 162.026 118.455 160.021 118.552C158.013 118.65 156.462 116.98 156.539 114.853C156.618 112.726 158.356 110.965 160.439 110.9C162.52 110.837 164.109 112.466 163.956 114.559V114.56Z"
          fill="url(#paint61_linear_22138_1220)"
        />
        <path
          d="M174.864 113.842C174.619 115.864 172.752 117.671 170.747 117.847C168.739 118.022 167.278 116.451 167.447 114.369C167.618 112.287 169.418 110.52 171.499 110.404C173.58 110.29 175.11 111.819 174.864 113.842V113.842Z"
          fill="url(#paint62_linear_22138_1220)"
        />
        <path
          d="M185.773 112.775C185.436 114.693 183.479 116.479 181.473 116.732C179.466 116.986 178.093 115.541 178.355 113.537C178.62 111.531 180.479 109.786 182.561 109.619C184.641 109.453 186.111 110.856 185.773 112.775H185.773Z"
          fill="url(#paint63_linear_22138_1220)"
        />
        <path
          d="M196.68 111.367C196.251 113.148 194.205 114.886 192.199 115.217C190.191 115.55 188.909 114.257 189.263 112.363C189.62 110.468 191.539 108.771 193.621 108.552C195.702 108.334 197.112 109.585 196.68 111.367Z"
          fill="url(#paint64_linear_22138_1220)"
        />
        <path
          d="M106.32 122.205C106.851 124.099 105.821 125.289 103.945 124.82C102.07 124.351 99.9574 122.492 99.3122 120.711C98.6663 118.929 99.8256 117.8 101.811 118.155C103.8 118.512 105.785 120.31 106.32 122.205V122.205Z"
          fill="url(#paint65_linear_22138_1220)"
        />
        <path
          d="M116.625 123.97C117.018 125.975 115.86 127.344 113.984 126.986C112.11 126.627 110.124 124.745 109.618 122.827C109.111 120.908 110.366 119.601 112.353 119.871C114.341 120.144 116.228 121.965 116.625 123.97V123.97Z"
          fill="url(#paint66_linear_22138_1220)"
        />
        <path
          d="M126.93 125.213C127.185 127.295 125.898 128.818 124.024 128.569C122.149 128.32 120.291 126.443 119.923 124.422C119.555 122.399 120.908 120.938 122.894 121.126C124.882 121.315 126.673 123.129 126.93 125.212V125.213Z"
          fill="url(#paint67_linear_22138_1220)"
        />
        <path
          d="M137.236 125.942C137.352 128.068 135.939 129.716 134.063 129.578C132.189 129.44 130.457 127.596 130.228 125.503C129.999 123.408 131.449 121.823 133.436 121.927C135.423 122.033 137.117 123.816 137.236 125.943V125.942Z"
          fill="url(#paint68_linear_22138_1220)"
        />
        <path
          d="M147.541 126.158C147.518 128.296 145.979 130.042 144.103 130.015C142.229 129.987 140.624 128.201 140.533 126.07C140.443 123.939 141.99 122.254 143.977 122.274C145.965 122.296 147.561 124.019 147.541 126.158Z"
          fill="url(#paint69_linear_22138_1220)"
        />
        <path
          d="M157.846 125.852C157.686 127.968 156.018 129.787 154.143 129.87C152.267 129.953 150.791 128.252 150.838 126.116C150.887 123.978 152.531 122.221 154.519 122.158C156.507 122.096 158.006 123.735 157.846 125.852Z"
          fill="url(#paint70_linear_22138_1220)"
        />
        <path
          d="M168.151 125.031C167.852 127.092 166.057 128.956 164.183 129.15C162.306 129.342 160.958 127.755 161.144 125.646C161.33 123.538 163.073 121.735 165.061 121.588C167.048 121.442 168.45 122.968 168.151 125.031V125.031Z"
          fill="url(#paint71_linear_22138_1220)"
        />
        <path
          d="M178.456 123.69C178.018 125.664 176.097 127.546 174.222 127.849C172.347 128.154 171.125 126.703 171.448 124.656C171.774 122.608 173.614 120.787 175.602 120.556C177.589 120.327 178.893 121.715 178.456 123.69Z"
          fill="url(#paint72_linear_22138_1220)"
        />
        <path
          d="M188.761 121.836C188.185 123.689 186.136 125.564 184.262 125.977C182.386 126.391 181.292 125.107 181.753 123.154C182.219 121.199 184.156 119.387 186.144 119.072C188.13 118.759 189.338 119.981 188.761 121.836Z"
          fill="url(#paint73_linear_22138_1220)"
        />
        <path
          d="M199.067 119.462C198.353 121.161 196.176 123.001 194.302 123.525C192.425 124.05 191.458 122.958 192.059 121.132C192.663 119.304 194.697 117.527 196.686 117.128C198.671 116.731 199.781 117.76 199.067 119.461V119.462Z"
          fill="url(#paint74_linear_22138_1220)"
        />
        <path
          d="M104.888 130.617C105.689 132.443 105.029 133.421 103.329 132.742C101.632 132.064 99.4057 130.09 98.4541 128.391C97.5002 126.69 98.3044 125.795 100.15 126.346C101.999 126.898 104.083 128.79 104.888 130.617Z"
          fill="url(#paint75_linear_22138_1220)"
        />
        <path
          d="M114.35 133.311C114.967 135.264 114.141 136.46 112.442 135.924C110.745 135.389 108.684 133.406 107.916 131.553C107.148 129.698 108.086 128.585 109.932 129.02C111.781 129.457 113.729 131.356 114.35 133.311Z"
          fill="url(#paint76_linear_22138_1220)"
        />
        <path
          d="M123.811 135.319C124.243 137.366 123.253 138.753 121.554 138.361C119.857 137.968 117.959 136.003 117.377 134.03C116.794 132.055 117.867 130.749 119.713 131.067C121.562 131.387 123.375 133.27 123.811 135.319Z"
          fill="url(#paint77_linear_22138_1220)"
        />
        <path
          d="M133.273 136.635C133.52 138.743 132.366 140.296 130.667 140.046C128.969 139.796 127.237 137.876 126.839 135.815C126.441 133.752 127.648 132.281 129.496 132.483C131.344 132.687 133.023 134.526 133.273 136.635L133.273 136.635Z"
          fill="url(#paint78_linear_22138_1220)"
        />
        <path
          d="M142.734 137.26C142.797 139.396 141.477 141.088 139.778 140.98C138.08 140.874 136.514 139.025 136.3 136.909C136.087 134.791 137.429 133.183 139.276 133.269C141.123 133.357 142.668 135.123 142.734 137.26Z"
          fill="url(#paint79_linear_22138_1220)"
        />
        <path
          d="M152.195 137.198C152.073 139.33 150.589 141.133 148.891 141.168C147.193 141.204 145.79 139.453 145.762 137.316C145.734 135.176 147.212 133.456 149.058 133.426C150.906 133.398 152.316 135.066 152.195 137.198Z"
          fill="url(#paint80_linear_22138_1220)"
        />
        <path
          d="M161.656 136.451C161.35 138.543 159.701 140.432 158.003 140.61C156.303 140.789 155.067 139.164 155.222 137.037C155.38 134.91 156.992 133.104 158.839 132.959C160.686 132.814 161.961 134.357 161.656 136.452L161.656 136.451Z"
          fill="url(#paint81_linear_22138_1220)"
        />
        <path
          d="M171.118 135.01C170.627 137.031 168.813 138.977 167.116 139.299C165.417 139.62 164.344 138.146 164.684 136.065C165.027 133.982 166.773 132.118 168.621 131.856C170.468 131.595 171.608 132.986 171.118 135.01H171.118Z"
          fill="url(#paint82_linear_22138_1220)"
        />
        <path
          d="M180.579 132.878C179.904 134.795 177.924 136.773 176.227 137.237C174.528 137.701 173.621 136.406 174.145 134.402C174.672 132.396 176.554 130.501 178.402 130.123C180.249 129.747 181.254 130.96 180.578 132.879L180.579 132.878Z"
          fill="url(#paint83_linear_22138_1220)"
        />
        <path
          d="M190.041 130.058C189.181 131.838 187.037 133.82 185.34 134.427C183.641 135.034 182.898 133.944 183.607 132.05C184.319 130.155 186.336 128.256 188.184 127.761C190.031 127.269 190.902 128.276 190.041 130.058Z"
          fill="url(#paint84_linear_22138_1220)"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path
          d="M154.265 125.335C128.821 125.335 108.194 104.708 108.194 79.264C108.194 73.4809 109.263 67.9476 111.209 62.8477C104.374 70.885 100.248 81.297 100.248 92.6749C100.248 118.119 120.874 138.746 146.319 138.746C165.979 138.746 182.76 126.429 189.375 109.092C180.924 119.029 168.333 125.336 154.266 125.336L154.265 125.335Z"
          fill="#BFC8D6"
        />
      </g>
      <path
        d="M121.519 97.1023V93.8125L128.67 82.5455H131.593V87.0114H129.897L125.712 93.642V93.7784H136.067V97.1023H121.519ZM129.948 100V96.0966L130.033 94.6562V82.5455H133.979V100H129.948ZM145.498 100.426C143.975 100.426 142.663 100.065 141.56 99.3438C140.458 98.6165 139.609 97.5739 139.012 96.2159C138.415 94.8523 138.12 93.2131 138.126 91.2983C138.131 89.3835 138.43 87.7585 139.021 86.4233C139.617 85.0824 140.464 84.0625 141.56 83.3636C142.663 82.6591 143.975 82.3068 145.498 82.3068C147.021 82.3068 148.333 82.6591 149.435 83.3636C150.543 84.0625 151.396 85.0824 151.992 86.4233C152.589 87.7642 152.884 89.3892 152.879 91.2983C152.879 93.2187 152.58 94.8608 151.984 96.2244C151.387 97.5881 150.538 98.6307 149.435 99.3523C148.339 100.068 147.026 100.426 145.498 100.426ZM145.498 96.9744C146.407 96.9744 147.143 96.5114 147.705 95.5852C148.268 94.6534 148.546 93.2244 148.54 91.2983C148.54 90.0369 148.413 88.9972 148.157 88.179C147.901 87.3551 147.546 86.7415 147.092 86.3381C146.637 85.9347 146.106 85.733 145.498 85.733C144.594 85.733 143.864 86.1903 143.308 87.1051C142.751 88.0142 142.469 89.4119 142.464 91.2983C142.458 92.5767 142.58 93.6364 142.83 94.4773C143.086 95.3182 143.444 95.946 143.904 96.3608C144.364 96.7699 144.896 96.9744 145.498 96.9744ZM155.082 97.1023V93.8125L162.232 82.5455H165.156V87.0114H163.46L159.275 93.642V93.7784H169.63V97.1023H155.082ZM163.511 100V96.0966L163.596 94.6562V82.5455H167.542V100H163.511Z"
        fill="black"
      />
      <path
        d="M64.2824 189.941C64.2824 189.941 63.0615 196.294 65.1102 196.667C67.1589 197.04 76.7201 200.641 74.9815 197.971C73.2428 195.302 68.8353 192.942 69.0831 190.893C69.3315 188.845 64.2817 189.942 64.2817 189.942L64.2824 189.941Z"
        fill="#0D0D0D"
      />
      <path
        d="M69.9954 190.193C68.7537 193.006 63.6207 191.02 63.6207 191.02C63.6207 191.02 63.6186 191.013 63.6129 191C63.4116 190.456 59.1652 178.958 59.8133 173.139C60.4742 167.179 58.6532 158.239 57.1638 155.259C55.6736 152.278 57.1638 127.774 57.1638 127.774C57.1638 127.774 58.8179 125.385 60.934 123.085C64.0081 119.748 68.0612 116.603 69.4569 121.235C71.8164 129.058 69.2506 158.156 70.5747 160.888C71.898 163.62 71.2378 187.376 69.9961 190.192L69.9954 190.193Z"
        fill="url(#paint85_linear_22138_1220)"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.1"
        d="M64.8474 181.579C64.0031 185.178 63.6902 188.414 63.6129 190.997C63.4116 190.453 59.1652 178.955 59.8133 173.136C60.4742 167.176 58.6532 158.236 57.1638 155.255C55.6736 152.275 57.1638 127.771 57.1638 127.771C57.1638 127.771 58.8179 125.382 60.934 123.082C62.2151 123.283 63.6508 123.797 65.2577 124.709C66.6161 126.398 67.2821 128.98 66.8897 131.091C66.5073 133.15 65.2384 134.668 64.1578 136.386C63.0651 138.125 62.6927 139.957 62.6383 141.996C62.576 144.318 62.919 146.593 63.3236 148.873C64.2967 154.349 66.4822 159.477 67.5972 164.901C68.7694 170.617 66.127 176.128 64.8481 181.579H64.8474Z"
        fill="url(#paint86_linear_22138_1220)"
      />
      <path
        d="M14.573 189.986C14.4277 190.019 10.0201 190.648 11.138 191.89C12.2558 193.131 13.5798 200.251 17.2641 200.995C20.9476 201.74 19.6644 199.93 18.175 198.781C16.6848 197.633 16.7779 193.597 17.2433 193.349C17.7088 193.101 16.4664 189.554 14.573 189.986Z"
        fill="#0D0D0D"
      />
      <path
        d="M44.0838 109.727C44.0838 109.727 36.1367 117.954 37.4614 131.431C38.7862 144.909 34.978 156.811 34.978 156.811C34.978 156.811 14.4485 178.34 13.2891 186.566C13.2891 186.566 13.2061 191.861 18.1729 191.074C23.1397 190.286 42.5106 167.707 46.9804 161.799C51.4502 155.892 56.914 130.424 62.2531 128.849C67.5923 127.274 69.3309 128.061 69.9518 123.467C70.5726 118.872 69.0825 110.34 69.0825 110.34L44.0824 109.727L44.0838 109.727Z"
        fill="url(#paint87_linear_22138_1220)"
      />
      <path
        d="M109.526 66.8539C109.526 66.8539 109.074 68.0534 108.289 69.8636C106.476 74.0406 102.883 81.4585 98.9543 84.8413C93.3216 89.6964 74.4849 75.0395 74.4849 75.0395L79.0814 62.4514C79.0814 62.4514 95.467 76.0578 95.7677 76.0578C95.9518 76.0277 100.367 70.7287 102.6 67.3981C102.754 67.1697 102.896 66.9513 103.027 66.7422C103.589 65.8507 103.922 65.1754 103.865 64.8947C103.849 64.8202 103.835 64.7407 103.83 64.6634C103.703 63.6301 104.218 62.649 104.808 62.2101C104.905 62.1327 105.002 62.0783 105.099 62.0361C105.844 61.7181 105.799 60.0074 106.914 58.7184C108.027 57.4295 110.888 59.1903 113.265 61.7088C115.638 64.2244 109.527 66.8546 109.527 66.8546L109.526 66.8539Z"
        fill="url(#paint88_linear_22138_1220)"
      />
      <path
        d="M109.526 66.8507C109.526 66.8507 109.074 68.0502 108.289 69.8604L102.749 67.4737C102.749 67.4737 102.692 67.4436 102.6 67.3942C102.754 67.1658 102.896 66.9474 103.028 66.7383C103.589 65.8468 103.922 65.1715 103.865 64.8908C103.85 64.8163 103.835 64.7368 103.83 64.6595C103.704 63.6262 104.218 62.6451 104.809 62.2062C104.905 62.1288 105.002 62.0744 105.099 62.0322C105.844 61.7142 105.8 60.0035 106.915 58.7145C108.027 57.4256 110.888 59.1864 113.265 61.7049C115.639 64.2205 109.528 66.8507 109.528 66.8507H109.526Z"
        fill="#D8DEE8"
      />
      <path
        d="M65.2578 55.5C65.2578 55.5 76.1087 58.7367 80.9874 63.0032L86.8407 66.8042C86.8407 66.8042 82.5091 80.6934 79.6798 80.7944C76.8506 80.8954 67.3653 74.7742 67.3653 74.7742L65.2578 55.5007V55.5Z"
        fill="url(#paint89_linear_22138_1220)"
      />
      <path
        d="M104.93 58.7163C104.93 58.7163 104.169 61.32 106.296 63.2577C108.424 65.1954 109.378 64.8868 110.288 64.0726C111.197 63.2577 106.796 58.4692 104.93 58.7163Z"
        fill="#D8DEE8"
      />
      <path
        d="M72.501 80.2582C68.488 95.6283 69.3867 105.83 70.3728 110.782C70.4845 111.351 70.599 111.853 70.7079 112.282C70.8397 112.807 70.7451 113.36 70.4444 113.812C70.1465 114.262 69.6717 114.562 69.1382 114.644C62.17 115.71 40.8248 118.22 39.558 109.19C38.413 101.047 31.8844 79.285 32.8476 66.1376C33.1627 61.8411 34.2783 58.4662 36.64 56.7848C36.64 56.7848 41.6218 53.5416 52.8071 53.1786C63.9924 52.8162 78.3987 57.6584 72.501 80.2582Z"
        fill="url(#paint90_linear_22138_1220)"
      />
      <path
        d="M48.8461 53.781C49.8028 52.2551 51.3732 49.7151 51.119 48.3989L55.0131 46.3008C55.0131 46.6667 55.0331 47.0226 55.0739 47.3878C55.2472 48.9252 55.4914 50.5701 56.2605 51.935C56.9959 53.2397 58.1438 54.0224 59.4643 54.5337C59.4449 55.1516 58.6035 54.1319 58.6035 55.2354C58.6035 56.8631 58.7238 58.3368 57.4528 58.5459C56.3872 58.7206 55.2386 56.0689 53.3102 55.3142C52.8655 55.1925 49.8572 57.012 49.5264 56.7249C49.4956 56.6984 48.6191 54.1398 48.8454 53.7803L48.8461 53.781Z"
        fill="url(#paint91_linear_22138_1220)"
      />
      <path
        d="M46.5679 54.3257C46.5679 54.3257 48.4741 50.8147 49.9628 51.9175C51.4516 53.0203 54.6597 55.4306 55.6737 56.4145C56.7292 57.4392 57.4531 58.6079 57.0392 58.6079C56.6253 58.6079 54.1606 56.6623 54.2551 57.0762C54.3489 57.4901 54.7019 59.3111 52.8079 58.6079C50.9138 57.904 47.2295 56.9545 46.5679 54.325V54.3257Z"
        fill="url(#paint92_linear_22138_1220)"
      />
      <path
        d="M57.039 58.6108C57.039 58.6108 58.0831 53.924 57.6212 53.5165C57.1593 53.1091 56.7132 52.6766 56.6975 52.5677C56.6817 52.4589 57.5732 51.5817 58.323 52.0435C59.0727 52.5054 60.7119 54.8026 60.4899 54.8649C60.2679 54.9272 59.5074 54.5871 59.6155 55.238C59.7244 55.8889 57.8461 59.392 57.039 58.6108Z"
        fill="url(#paint93_linear_22138_1220)"
      />
      <path
        d="M59.5026 45.448C60.5874 44.2342 60.8645 41.8947 59.8957 40.2606C59.0622 38.8549 57.5806 38.469 56.6933 38.2377C54.1233 37.5681 50.7369 38.2663 49.362 40.633C48.5471 42.0358 49.7215 45.3398 49.8991 45.9285C50.0996 46.5951 50.55 46.8759 50.9403 47.4115C51.4816 48.1555 52.3281 49.3363 53.7044 51.3056C53.8727 50.8022 54.114 50.1842 54.4677 49.5268C54.6038 49.274 55.148 48.2865 55.9049 47.5096C57.4216 45.9521 58.4735 46.598 59.5026 45.4473V45.448Z"
        fill="url(#paint94_linear_22138_1220)"
      />
      <path
        d="M51.6455 49.5286C51.6455 49.5286 53.0805 52.7159 56.5049 52.9443C59.5704 53.1491 60.6847 46.009 58.8808 44.2166C58.2786 43.6187 56.9982 43.2306 55.9119 43.3988C55.2703 43.4984 55.0333 43.7433 53.9821 43.8843C53.0118 44.0147 52.7547 43.8679 52.4461 44.082C52.0594 44.3505 51.7257 45.0917 52.1267 47.2779C51.6032 46.579 50.9272 46.246 50.4102 46.425C50.3279 46.4537 50.1045 46.5754 49.9655 46.7867C49.4636 47.5514 50.0787 48.6986 51.6455 49.5286Z"
        fill="url(#paint95_linear_22138_1220)"
      />
      <path
        d="M61.2562 43.199C57.1881 43.6186 54.4218 43.8399 52.5564 43.9537C49.6212 44.1299 48.923 44.0432 48.923 44.0432C48.923 44.0432 48.3566 39.9257 50.9667 37.8125C51.7193 37.2039 52.7347 36.7599 54.1032 36.6303C59.269 36.1412 61.3972 39.1509 61.2555 43.199H61.2562Z"
        fill="url(#paint96_linear_22138_1220)"
      />
      <path
        d="M52.8081 43.9356C52.864 43.6298 58.2396 40.8235 61.263 42.4397C64.2856 44.0566 63.263 44.6624 61.263 44.5106C59.2629 44.3588 52.8081 43.9349 52.8081 43.9349V43.9356Z"
        fill="url(#paint97_linear_22138_1220)"
      />
      <path
        d="M52.8081 43.934C52.8081 43.934 56.5898 45.1427 57.6603 44.6472C58.7316 44.1517 61.9533 42.6221 62.9193 43.5652C62.9193 43.5652 62.1831 41.7864 57.9869 41.939C53.7906 42.0922 52.8081 43.9333 52.8081 43.9333V43.934Z"
        fill="url(#paint98_linear_22138_1220)"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.3"
        d="M52.5564 43.9537C49.6212 44.1298 48.923 44.0432 48.923 44.0432C48.923 44.0432 48.3566 39.9257 50.9667 37.8125C50.6488 39.5411 50.5993 41.9794 52.2385 43.5842C52.3702 43.7131 52.4769 43.8377 52.5564 43.9544V43.9537Z"
        fill="url(#paint99_linear_22138_1220)"
      />
      <path
        d="M46.8216 13.5197C46.4808 13.2548 45.9895 13.3171 45.7253 13.6579C45.4604 13.9988 45.5227 14.49 45.8635 14.7543L113.778 67.462C114.119 67.7269 114.61 67.6646 114.874 67.3238C115.139 66.9829 115.077 66.4917 114.736 66.2274L46.8216 13.5197Z"
        fill="#BFC8D6"
      />
      <path
        d="M99.7086 54.5607L98.7507 55.7952C98.5742 56.0226 98.6156 56.3501 98.843 56.5266L114.484 68.6635C114.712 68.84 115.039 68.7987 115.216 68.5712L116.173 67.3368C116.35 67.1093 116.309 66.7818 116.081 66.6053L100.44 54.4684C100.213 54.2919 99.8851 54.3333 99.7086 54.5607Z"
        fill="url(#paint100_linear_22138_1220)"
      />
      <path
        d="M44.1442 11.4435L43.1863 12.678C43.0098 12.9054 43.0511 13.2329 43.2786 13.4094L48.6294 17.5614C48.8568 17.7379 49.1843 17.6966 49.3608 17.4692L50.3187 16.2347C50.4952 16.0072 50.4539 15.6798 50.2264 15.5033L44.8756 11.3513C44.6482 11.1748 44.3207 11.2161 44.1442 11.4435Z"
        fill="url(#paint101_linear_22138_1220)"
      />
      <path
        d="M38.8763 21.2247C37.9711 21.9579 36.8032 22.3224 35.7076 22.215C34.6113 22.1076 33.7047 21.5405 33.2507 20.6733C33.2142 20.6045 33.1454 20.4735 33.1096 20.4055C32.2969 18.8501 33.1032 16.7334 34.9586 15.7366L42.9444 11.4472C43.7192 11.0312 44.6637 11.2524 45.0547 11.8733L45.2731 12.2249C45.2731 12.2249 44.5606 16.6195 38.8756 21.2247H38.8763Z"
        fill="url(#paint102_linear_22138_1220)"
      />
      <path
        d="M43.5701 16.4062L41.2643 12.1133L35.1382 15.4037L38.5267 21.7124C38.9585 21.4668 38.8633 21.4919 39.023 21.3616C40.3685 20.2695 41.4326 19.1954 42.2797 18.1893C42.5003 17.9243 42.6736 17.6844 42.8669 17.4288C43.1383 17.0693 43.2214 16.8072 43.5701 16.4062Z"
        fill="url(#paint103_linear_22138_1220)"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
        <path
          d="M42.8677 17.3633L38.2833 19.8259C38.1673 19.8882 38.1221 20.0228 38.1744 20.1195C38.2267 20.2162 38.3635 20.2527 38.4795 20.1904L42.2568 18.1617C42.5383 18.0106 42.612 17.5015 42.8684 17.3633H42.8677Z"
          fill="url(#paint104_linear_22138_1220)"
        />
        <path
          d="M37.9484 19.5892C37.9907 19.6672 38.1003 19.6901 38.1941 19.6393L43.3241 16.8838C43.4179 16.8337 43.4595 16.7291 43.4179 16.651L43.3742 16.5694C43.332 16.4914 43.2224 16.4684 43.1286 16.5193L37.9986 19.2748C37.9048 19.3249 37.8632 19.4295 37.9048 19.5075L37.9484 19.5892Z"
          fill="url(#paint105_linear_22138_1220)"
        />
        <path
          d="M37.4587 18.6751C37.5009 18.7532 37.6105 18.7761 37.7043 18.7252L42.8344 15.9697C42.9282 15.9196 42.9697 15.815 42.9282 15.737L42.8845 15.6554C42.8423 15.5773 42.7327 15.5544 42.6389 15.6052L37.5088 18.3607C37.415 18.4109 37.3735 18.5154 37.415 18.5935L37.4587 18.6751Z"
          fill="url(#paint106_linear_22138_1220)"
        />
        <path
          d="M37.1652 18.1321C37.2075 18.2102 37.3171 18.2331 37.4109 18.1823L42.5409 15.4267C42.6347 15.3766 42.6763 15.2721 42.6347 15.194L42.591 15.1124C42.5488 15.0343 42.4392 15.0114 42.3454 15.0623L37.2154 17.8178C37.1216 17.8679 37.08 17.9724 37.1216 18.0505L37.1652 18.1321Z"
          fill="url(#paint107_linear_22138_1220)"
        />
        <path
          d="M36.8713 17.5853C36.9136 17.6633 37.0231 17.6862 37.1169 17.6354L42.247 14.8799C42.3408 14.8297 42.3823 14.7252 42.3408 14.6471L42.2971 14.5655C42.2549 14.4875 42.1453 14.4645 42.0515 14.5154L36.9214 17.2709C36.8276 17.321 36.7861 17.4256 36.8276 17.5036L36.8713 17.5853Z"
          fill="url(#paint108_linear_22138_1220)"
        />
        <path
          d="M36.3816 16.6712C36.4238 16.7492 36.5334 16.7722 36.6272 16.7213L41.7572 13.9658C41.851 13.9157 41.8926 13.8111 41.851 13.7331L41.8074 13.6514C41.7651 13.5734 41.6555 13.5505 41.5617 13.6013L36.4317 16.3568C36.3379 16.407 36.2963 16.5115 36.3379 16.5896L36.3816 16.6712Z"
          fill="url(#paint109_linear_22138_1220)"
        />
        <path
          d="M36.0881 16.1243C36.1303 16.2024 36.2399 16.2253 36.3337 16.1744L41.4638 13.4189C41.5576 13.3688 41.5991 13.2643 41.5576 13.1862L41.5139 13.1046C41.4716 13.0265 41.3621 13.0036 41.2683 13.0544L36.1382 15.81C36.0444 15.8601 36.0029 15.9646 36.0444 16.0427L36.0881 16.1243Z"
          fill="url(#paint110_linear_22138_1220)"
        />
      </g>
      <path
        d="M45.5085 12.1541L45.2529 11.748C44.791 11.0176 43.6968 10.8078 42.8476 11.264L34.8582 15.5548C32.9326 16.5888 32.1463 18.7578 33.0343 20.4113L33.1897 20.7013C33.6845 21.6229 34.5488 22.2659 35.7203 22.3798C36.6727 22.4729 37.6423 22.2509 38.5109 21.7217C38.6878 21.6136 38.8547 21.4969 39.0143 21.3665C41.2077 19.5878 42.6664 17.8484 43.6345 16.3718C45.1791 14.0123 45.4706 12.3216 45.4899 12.2085L45.5071 12.1541H45.5085ZM45.008 12.235C44.945 12.545 44.6127 13.993 43.3975 15.9221C43.3975 15.9221 43.3853 15.9071 43.3868 15.9107C42.448 17.4109 40.9671 19.1817 38.7129 21.0063C38.5782 21.1138 38.1479 21.3988 38.0698 21.441C37.3494 21.8284 36.614 22.0382 35.8492 21.9645C34.813 21.8628 34.0676 21.2849 33.6301 20.47L33.474 20.18C32.6863 18.7127 33.2541 16.6518 34.9563 15.7374L42.9457 11.4466C43.5837 11.1043 44.4559 11.347 44.8061 11.9099L45.008 12.235Z"
        fill="url(#paint111_linear_22138_1220)"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.3"
        d="M70.4449 113.811C70.147 114.261 69.6722 114.561 69.1387 114.643C62.1705 115.709 40.8253 118.219 39.5585 109.189C38.4135 101.046 31.8849 79.2841 32.8481 66.1367C35.6816 66.3601 48.6249 67.9148 47.8501 77.65C46.9808 88.5768 45.2722 92.178 55.0669 92.178C64.8615 92.178 63.6198 98.8834 64.4892 106.458C64.9539 110.503 67.5067 110.475 70.3725 110.781C70.4842 111.35 70.5988 111.852 70.7077 112.281C70.8394 112.806 70.7449 113.359 70.4441 113.811H70.4449Z"
        fill="url(#paint112_linear_22138_1220)"
      />
      <path
        d="M56.8596 58.5568C56.8596 58.5568 58.2502 58.8382 58.4364 59.6767C58.6226 60.5146 58.5295 65.2021 59.0881 65.3267C59.6466 65.4505 59.1188 59.169 58.9327 58.7709C58.7465 58.3727 57.5929 57.9946 56.8596 58.0627C56.1263 58.1307 56.8596 58.5575 56.8596 58.5575V58.5568Z"
        fill="url(#paint113_linear_22138_1220)"
      />
      <path
        d="M113.371 63.3287C113.371 63.3287 111.799 65.9776 109.646 64.5704C107.494 63.1633 106.956 61.4662 107.867 60.7215C108.778 59.9767 112.668 61.5908 113.372 63.3287H113.371Z"
        fill="#D8DEE8"
      />
      <path
        d="M108.584 68.2239C107.681 68.8211 106.056 70.173 103.898 71.9003C96.1957 78.0686 81.7135 89.0019 69.1347 87.2317C53.0306 84.9703 32.9042 78.2627 36.9688 70.3399C41.0304 62.4135 42.7948 59.2949 48.8322 61.653C48.8322 61.653 68.5253 77.2931 77.4457 74.7446C84.802 72.6471 96.2867 68.2704 100.093 66.7974C100.905 66.4823 101.942 66.7043 101.942 66.7043C101.942 66.7043 101.331 60.8789 104.302 60.4693C107.269 60.0633 107.029 55.8062 108.399 57.6401C109.769 59.4711 112.712 65.4977 108.583 68.2253L108.584 68.2239Z"
        fill="url(#paint114_linear_22138_1220)"
      />
      <path
        d="M56.7594 64.3303C56.7594 64.3303 47.3399 51.3977 39.0032 58.4705C30.6665 65.5433 29.6561 75.0401 38.5478 80.496C47.4388 85.9519 50.7478 86.1538 50.7478 86.1538L60.1057 67.0579L56.7587 64.3296L56.7594 64.3303Z"
        fill="url(#paint115_linear_22138_1220)"
      />
      <path
        d="M135.787 26.9341C135.787 26.9341 138.095 22.4622 142.051 24.1965C146.007 25.9309 145.642 25.0208 148.389 25.3659C151.137 25.7111 154.537 27.618 152.324 28.0018C150.111 28.3857 132.783 28.8253 132.783 28.8253C132.783 28.8253 133.039 27.169 135.787 26.9349V26.9341Z"
        fill="white"
      />
      <path
        d="M82.3337 19.5769C82.3337 19.5769 85.4451 13.5489 90.7785 15.8862C96.1119 18.2235 95.6178 16.9968 99.3221 17.4623C103.026 17.9277 107.609 20.4978 104.626 21.0148C101.643 21.5325 78.2842 22.1247 78.2842 22.1247C78.2842 22.1247 78.6301 19.892 82.3337 19.5762V19.5769Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_22138_1220"
        x1="98.0518"
        y1="55.7697"
        x2="105.227"
        y2="55.7697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_22138_1220"
        x1="107.647"
        y1="52.8523"
        x2="114.554"
        y2="52.8523"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_22138_1220"
        x1="117.219"
        y1="50.6178"
        x2="123.912"
        y2="50.6178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_22138_1220"
        x1="126.763"
        y1="49.074"
        x2="133.306"
        y2="49.074"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_22138_1220"
        x1="136.278"
        y1="48.2157"
        x2="142.736"
        y2="48.2157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_22138_1220"
        x1="145.761"
        y1="48.0429"
        x2="152.203"
        y2="48.0429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_22138_1220"
        x1="155.21"
        y1="48.5593"
        x2="161.702"
        y2="48.5593"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_22138_1220"
        x1="164.621"
        y1="49.7591"
        x2="171.232"
        y2="49.7591"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_22138_1220"
        x1="173.997"
        y1="51.6502"
        x2="180.79"
        y2="51.6502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_22138_1220"
        x1="183.339"
        y1="54.2255"
        x2="190.374"
        y2="54.2255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_22138_1220"
        x1="99.1322"
        y1="63.8343"
        x2="106.458"
        y2="63.8343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_22138_1220"
        x1="109.505"
        y1="61.9047"
        x2="116.701"
        y2="61.9047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_22138_1220"
        x1="119.862"
        y1="60.4853"
        x2="126.962"
        y2="60.4853"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_22138_1220"
        x1="130.204"
        y1="59.5859"
        x2="137.242"
        y2="59.5859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_22138_1220"
        x1="140.529"
        y1="59.1989"
        x2="147.541"
        y2="59.1989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_22138_1220"
        x1="150.837"
        y1="59.3264"
        x2="157.857"
        y2="59.3264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_22138_1220"
        x1="161.126"
        y1="59.973"
        x2="168.191"
        y2="59.973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_22138_1220"
        x1="171.396"
        y1="61.1318"
        x2="178.54"
        y2="61.1318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_22138_1220"
        x1="181.648"
        y1="62.8047"
        x2="188.905"
        y2="62.8047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_22138_1220"
        x1="191.881"
        y1="64.9897"
        x2="199.284"
        y2="64.9897"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_22138_1220"
        x1="101.937"
        y1="72.762"
        x2="109.461"
        y2="72.762"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_22138_1220"
        x1="112.871"
        y1="71.6446"
        x2="120.346"
        y2="71.6446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_22138_1220"
        x1="123.798"
        y1="70.8759"
        x2="131.24"
        y2="70.8759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_22138_1220"
        x1="134.719"
        y1="70.446"
        x2="142.142"
        y2="70.446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_22138_1220"
        x1="145.631"
        y1="70.3589"
        x2="153.051"
        y2="70.3589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_22138_1220"
        x1="156.537"
        y1="70.6146"
        x2="163.967"
        y2="70.6146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_22138_1220"
        x1="167.434"
        y1="71.215"
        x2="174.89"
        y2="71.215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_22138_1220"
        x1="178.323"
        y1="72.1598"
        x2="185.821"
        y2="72.1598"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_22138_1220"
        x1="189.205"
        y1="73.4491"
        x2="196.758"
        y2="73.4491"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_22138_1220"
        x1="94.8661"
        y1="83.0576"
        x2="102.563"
        y2="83.0576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_22138_1220"
        x1="106.143"
        y1="82.418"
        x2="113.825"
        y2="82.418"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_22138_1220"
        x1="117.418"
        y1="81.9434"
        x2="125.091"
        y2="81.9434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_22138_1220"
        x1="128.692"
        y1="81.6432"
        x2="136.358"
        y2="81.6432"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_22138_1220"
        x1="139.964"
        y1="81.5158"
        x2="147.627"
        y2="81.5158"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_22138_1220"
        x1="151.233"
        y1="81.5598"
        x2="158.898"
        y2="81.5598"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_22138_1220"
        x1="162.501"
        y1="81.7713"
        x2="170.17"
        y2="81.7713"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_22138_1220"
        x1="173.768"
        y1="82.1583"
        x2="181.445"
        y2="82.1583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_22138_1220"
        x1="185.032"
        y1="82.7148"
        x2="192.722"
        y2="82.7148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_22138_1220"
        x1="100.027"
        y1="92.6722"
        x2="107.772"
        y2="92.6722"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_22138_1220"
        x1="111.417"
        y1="92.6704"
        x2="119.162"
        y2="92.6704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_22138_1220"
        x1="122.807"
        y1="92.6718"
        x2="130.553"
        y2="92.6718"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_22138_1220"
        x1="134.198"
        y1="92.6726"
        x2="141.943"
        y2="92.6726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint42_linear_22138_1220"
        x1="145.588"
        y1="92.6712"
        x2="153.333"
        y2="92.6712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint43_linear_22138_1220"
        x1="156.978"
        y1="92.6699"
        x2="164.723"
        y2="92.6699"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_22138_1220"
        x1="168.368"
        y1="92.6711"
        x2="176.113"
        y2="92.6711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_22138_1220"
        x1="179.758"
        y1="92.6709"
        x2="187.504"
        y2="92.6709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_22138_1220"
        x1="191.149"
        y1="92.6702"
        x2="198.894"
        y2="92.6702"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_22138_1220"
        x1="94.8657"
        y1="102.285"
        x2="102.562"
        y2="102.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_22138_1220"
        x1="106.143"
        y1="102.926"
        x2="113.826"
        y2="102.926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_22138_1220"
        x1="117.418"
        y1="103.4"
        x2="125.091"
        y2="103.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_22138_1220"
        x1="128.692"
        y1="103.698"
        x2="136.358"
        y2="103.698"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_22138_1220"
        x1="139.964"
        y1="103.828"
        x2="147.627"
        y2="103.828"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_22138_1220"
        x1="151.233"
        y1="103.786"
        x2="158.898"
        y2="103.786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_22138_1220"
        x1="162.501"
        y1="103.572"
        x2="170.17"
        y2="103.572"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_22138_1220"
        x1="173.767"
        y1="103.186"
        x2="181.445"
        y2="103.186"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_22138_1220"
        x1="185.032"
        y1="102.628"
        x2="192.722"
        y2="102.628"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_22138_1220"
        x1="101.937"
        y1="112.587"
        x2="109.46"
        y2="112.587"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_22138_1220"
        x1="112.87"
        y1="113.7"
        x2="120.346"
        y2="113.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_22138_1220"
        x1="123.798"
        y1="114.473"
        x2="131.24"
        y2="114.473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_22138_1220"
        x1="134.718"
        y1="114.9"
        x2="142.141"
        y2="114.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_22138_1220"
        x1="145.631"
        y1="114.985"
        x2="153.05"
        y2="114.985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_22138_1220"
        x1="156.536"
        y1="114.728"
        x2="163.967"
        y2="114.728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_22138_1220"
        x1="167.433"
        y1="114.129"
        x2="174.89"
        y2="114.129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint63_linear_22138_1220"
        x1="178.323"
        y1="113.183"
        x2="185.821"
        y2="113.183"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_22138_1220"
        x1="189.204"
        y1="111.898"
        x2="196.758"
        y2="111.898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint65_linear_22138_1220"
        x1="99.1325"
        y1="121.506"
        x2="106.459"
        y2="121.506"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint66_linear_22138_1220"
        x1="109.505"
        y1="123.44"
        x2="116.701"
        y2="123.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint67_linear_22138_1220"
        x1="119.862"
        y1="124.853"
        x2="126.962"
        y2="124.853"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_22138_1220"
        x1="130.204"
        y1="125.754"
        x2="137.242"
        y2="125.754"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint69_linear_22138_1220"
        x1="140.529"
        y1="126.144"
        x2="147.541"
        y2="126.144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint70_linear_22138_1220"
        x1="150.837"
        y1="126.014"
        x2="157.858"
        y2="126.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint71_linear_22138_1220"
        x1="161.126"
        y1="125.372"
        x2="168.191"
        y2="125.372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint72_linear_22138_1220"
        x1="171.397"
        y1="124.21"
        x2="178.541"
        y2="124.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint73_linear_22138_1220"
        x1="181.648"
        y1="122.539"
        x2="188.906"
        y2="122.539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint74_linear_22138_1220"
        x1="191.881"
        y1="120.35"
        x2="199.285"
        y2="120.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint75_linear_22138_1220"
        x1="98.0509"
        y1="129.571"
        x2="105.227"
        y2="129.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint76_linear_22138_1220"
        x1="107.647"
        y1="132.489"
        x2="114.554"
        y2="132.489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint77_linear_22138_1220"
        x1="117.219"
        y1="134.723"
        x2="123.912"
        y2="134.723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint78_linear_22138_1220"
        x1="126.763"
        y1="136.269"
        x2="133.305"
        y2="136.269"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint79_linear_22138_1220"
        x1="136.278"
        y1="137.126"
        x2="142.736"
        y2="137.126"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint80_linear_22138_1220"
        x1="145.761"
        y1="137.297"
        x2="152.202"
        y2="137.297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint81_linear_22138_1220"
        x1="155.209"
        y1="136.785"
        x2="161.701"
        y2="136.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint82_linear_22138_1220"
        x1="164.621"
        y1="135.584"
        x2="171.232"
        y2="135.584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint83_linear_22138_1220"
        x1="173.997"
        y1="133.693"
        x2="180.79"
        y2="133.693"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint84_linear_22138_1220"
        x1="183.339"
        y1="131.116"
        x2="190.374"
        y2="131.116"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#D8DEE8" />
      </linearGradient>
      <linearGradient
        id="paint85_linear_22138_1220"
        x1="76.177"
        y1="108.255"
        x2="49.8604"
        y2="118.151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7532" />
      </linearGradient>
      <linearGradient
        id="paint86_linear_22138_1220"
        x1="54.0509"
        y1="117.479"
        x2="89.9585"
        y2="255.306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint87_linear_22138_1220"
        x1="88.8212"
        y1="97.8167"
        x2="45.5652"
        y2="153.646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#16A34A" />
      </linearGradient>
      <linearGradient
        id="paint88_linear_22138_1220"
        x1="85.5819"
        y1="115.078"
        x2="114.308"
        y2="62.2696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBA74E" />
        <stop offset="1" stopColor="#F7CFBB" />
      </linearGradient>
      <linearGradient
        id="paint89_linear_22138_1220"
        x1="93.9515"
        y1="51.8002"
        x2="81.4034"
        y2="71.6049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7D35" />
      </linearGradient>
      <linearGradient
        id="paint90_linear_22138_1220"
        x1="87.4157"
        y1="43.9998"
        x2="53.6951"
        y2="84.9521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#16A34A" />
      </linearGradient>
      <linearGradient
        id="paint91_linear_22138_1220"
        x1="51.8149"
        y1="71.5677"
        x2="66.9414"
        y2="54.6318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#544609" />
        <stop offset="1" stopColor="#F7CFBB" />
      </linearGradient>
      <linearGradient
        id="paint92_linear_22138_1220"
        x1="60.6464"
        y1="50.6698"
        x2="58.1727"
        y2="57.5291"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7D35" />
      </linearGradient>
      <linearGradient
        id="paint93_linear_22138_1220"
        x1="61.7666"
        y1="50.9136"
        x2="58.0308"
        y2="54.7809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7D35" />
      </linearGradient>
      <linearGradient
        id="paint94_linear_22138_1220"
        x1="46.2122"
        y1="58.6722"
        x2="56.39"
        y2="39.3362"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint95_linear_22138_1220"
        x1="52.6031"
        y1="63.1328"
        x2="64.0326"
        y2="47.8588"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBA74E" />
        <stop offset="1" stopColor="#F7CFBB" />
      </linearGradient>
      <linearGradient
        id="paint96_linear_22138_1220"
        x1="65.3444"
        y1="35.4831"
        x2="62.9201"
        y2="42.9056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#16A34A" />
      </linearGradient>
      <linearGradient
        id="paint97_linear_22138_1220"
        x1="66.578"
        y1="41.5623"
        x2="66.2002"
        y2="44.355"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7D35" />
      </linearGradient>
      <linearGradient
        id="paint98_linear_22138_1220"
        x1="66.2505"
        y1="41.5148"
        x2="65.789"
        y2="44.5574"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#16A34A" />
      </linearGradient>
      <linearGradient
        id="paint99_linear_22138_1220"
        x1="49.0777"
        y1="37.5977"
        x2="52.5084"
        y2="50.7665"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AA80F9" />
        <stop offset="1" stopColor="#6165D7" />
      </linearGradient>
      <linearGradient
        id="paint100_linear_22138_1220"
        x1="106.664"
        y1="62.5968"
        x2="108.261"
        y2="60.5387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint101_linear_22138_1220"
        x1="45.9535"
        y1="15.486"
        x2="47.5506"
        y2="13.4278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint102_linear_22138_1220"
        x1="33.0239"
        y1="20.2557"
        x2="45.8769"
        y2="13.3524"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint103_linear_22138_1220"
        x1="36.6798"
        y1="18.2778"
        x2="42.8054"
        y2="14.9878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint104_linear_22138_1220"
        x1="43.7471"
        y1="18.048"
        x2="36.7332"
        y2="18.0454"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint105_linear_22138_1220"
        x1="44.8748"
        y1="17.1864"
        x2="36.6252"
        y2="17.1834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint106_linear_22138_1220"
        x1="44.7654"
        y1="16.2234"
        x2="36.515"
        y2="16.2206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint107_linear_22138_1220"
        x1="44.8503"
        y1="15.6301"
        x2="36.6008"
        y2="15.6277"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint108_linear_22138_1220"
        x1="44.9356"
        y1="15.0331"
        x2="36.6859"
        y2="15.0301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint109_linear_22138_1220"
        x1="44.8255"
        y1="14.0704"
        x2="36.5758"
        y2="14.0673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint110_linear_22138_1220"
        x1="44.911"
        y1="13.4729"
        x2="36.6616"
        y2="13.4705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint111_linear_22138_1220"
        x1="40.028"
        y1="21.1232"
        x2="37.5305"
        y2="12.4367"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444B8C" />
        <stop offset="1" stopColor="#26264F" />
      </linearGradient>
      <linearGradient
        id="paint112_linear_22138_1220"
        x1="66.9095"
        y1="71.4451"
        x2="47.2329"
        y2="95.3417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D8539" />
        <stop offset="1" stopColor="#16A34A" />
      </linearGradient>
      <linearGradient
        id="paint113_linear_22138_1220"
        x1="60.2612"
        y1="56.9908"
        x2="56.4885"
        y2="59.6808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C7D35" />
      </linearGradient>
      <linearGradient
        id="paint114_linear_22138_1220"
        x1="57.393"
        y1="119.539"
        x2="78.9967"
        y2="51.2347"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBA74E" />
        <stop offset="1" stopColor="#F7CFBB" />
      </linearGradient>
      <linearGradient
        id="paint115_linear_22138_1220"
        x1="34.943"
        y1="103.556"
        x2="48.9067"
        y2="59.405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16A34A" />
        <stop offset="1" stopColor="#0C8639" />
      </linearGradient>
      <clipPath id="clip0_22138_1220">
        <rect
          width="204"
          height="200.341"
          fill="white"
          transform="translate(0 0.828125)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { MainLogoSvg, NotFoundSvg, WrongPaymentSvg };
